import React, { ReactNode } from 'react';
import SEO from '../core/meta/SEO';
import { PageProps } from 'gatsby';
import { Container } from '../core/layout/Layout';
import Heading from '../core/typography/Heading';
import BackgroundImage from 'gatsby-background-image';
import { ContentfulPromotion } from '../../../generated/graphql-types';
import {
  renderOptions,
  renderOptionsLowMarginHeaders,
} from '../../contentful/render-options';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import FaqAccordion from '../views/FaqAccordion/FaqAccordion';
import ResponsiveList from '../views/ResponsiveList/ResponsiveList';
import tw from 'twin.macro';
import Button from '../core/buttons/Button';
import useDatalayer from '../../../hooks/use-datalayer';
import CollectEmailFromPromotion from '../partials/CollectEmailFromPromotion/CollectEmailFromPromotion';
import { useAnalytics } from '../../analytics/AnalyticsProvider';

const PromotionPage = ({
  pageContext: { pageData },
}: PageProps<unknown, { pageData: Partial<ContentfulPromotion> }>) => {
  const datalayer = useDatalayer();
  const analytics = useAnalytics();

  return (
    <>
      <SEO
        title={pageData.metaTitle}
        description={pageData.metaDescription}
        metaImage={pageData.headerBackground}
        hidePageSuffix
        url={`/${pageData.slug}`}
      />
      <BackgroundImage fluid={pageData.headerBackground.fluid}>
        <div tw="bg-black bg-opacity-60 py-16">
          <Container>
            <Heading variant="h1" tw="text-center text-white">
              {pageData.title}
            </Heading>
            {pageData.headerCtaText && pageData.headerCtaLink && (
              <div tw="flex justify-center">
                <a href={pageData.headerCtaLink}>
                  <Button
                    variant="filled"
                    color="primary"
                    onClick={() => {
                      datalayer.triggerContentfulDatalayerAction(
                        pageData.headerCtaDatalayerAction,
                        { ab_variant_id: pageData.slug }
                      );
                      if (pageData.headerCtaEvent) {
                        analytics.submitEvent(pageData.headerCtaEvent as any, {
                          location: pageData.headerCtaEventLocation as any,
                        });
                      }
                    }}
                  >
                    {pageData.headerCtaText}
                  </Button>
                </a>
              </div>
            )}
          </Container>
        </div>
      </BackgroundImage>
      <div tw="pb-20">
        {pageData.introText && (
          <Container tw="mb-16">
            {/*//@ts-ignore*/}
            {renderRichText(pageData.introText, renderOptions)}
          </Container>
        )}
        {pageData.showCollectEmailForm && (
          <div tw="bg-primary-300 py-10 mb-16">
            <Container tw="flex justify-center">
              <CollectEmailFromPromotion abVariant={pageData.slug} />
            </Container>
          </div>
        )}
        <Container tw="mb-16">
          <div tw="-mx-5">
            <ResponsiveList
              sliderProps={{
                darkControls: true,
                customControlsStyle: tw`transform -translate-y-20!`,
              }}
              twGridItemSetting={tw`md:w-1/3 px-10 py-4`}
            >
              {pageData.text1 && pageData.image1 && (
                <div>
                  <BackgroundImage
                    fluid={pageData.image1.fluid}
                    style={{ height: '160px', width: '100%' }}
                    tw="rounded-2xl overflow-hidden mb-5"
                  />
                  <div>
                    {renderRichText(
                      // @ts-ignore
                      pageData.text1,
                      renderOptionsLowMarginHeaders
                    )}
                  </div>
                </div>
              )}
              {pageData.text2 && pageData.image2 && (
                <div>
                  <BackgroundImage
                    fluid={pageData.image2.fluid}
                    style={{ height: '160px', width: '100%' }}
                    tw="rounded-2xl overflow-hidden mb-5"
                  />
                  <div>
                    {renderRichText(
                      // @ts-ignore
                      pageData.text2,
                      renderOptionsLowMarginHeaders
                    )}
                  </div>
                </div>
              )}
              {pageData.text3 && pageData.image3 && (
                <div>
                  <BackgroundImage
                    fluid={pageData.image3.fluid}
                    style={{ height: '160px', width: '100%' }}
                    tw="rounded-2xl overflow-hidden mb-5"
                  />
                  <div>
                    {renderRichText(
                      // @ts-ignore
                      pageData.text3,
                      renderOptionsLowMarginHeaders
                    )}
                  </div>
                </div>
              )}
            </ResponsiveList>
          </div>
          {pageData.bodyCta1Url && pageData.bodyCta1Text && (
            <div tw="flex justify-center mt-6">
              <a href={pageData.bodyCta1Url}>
                <Button
                  variant="filled"
                  color="primary"
                  onClick={() => {
                    datalayer.triggerContentfulDatalayerAction(
                      pageData.bodyCta1DatalayerAction,
                      { ab_variant_id: pageData.slug }
                    );
                    if (pageData.bodyCta1Event) {
                      analytics.submitEvent(pageData.bodyCta1Event as any, {
                        location: pageData.bodyCta1EventLocation as any,
                      });
                    }
                  }}
                >
                  {pageData.bodyCta1Text}
                </Button>
              </a>
            </div>
          )}
        </Container>
        {pageData.featureImage1 &&
          pageData.featureImage2 &&
          pageData.featureText1 &&
          pageData.featureText2 && (
            <div tw="bg-primary-300 py-16 pb-20">
              <Container>
                {pageData.featureSectionTitle && (
                  <Heading variant="h2" tw="text-center">
                    {pageData.featureSectionTitle}
                  </Heading>
                )}

                <div tw="flex flex-wrap -mx-4">
                  {pageData.featureImage1 && pageData.featureText1 && (
                    <div tw="w-full md:w-1/2 px-4 mb-10 md:mb-0">
                      <div tw="rounded-card bg-white">
                        <BackgroundImage
                          fluid={pageData.featureImage1.fluid}
                          style={{ height: '200px', width: '100%' }}
                          tw="rounded-t-2xl overflow-hidden"
                        />
                        <div tw="p-8 pt-4">
                          {renderRichText(
                            //@ts-ignore
                            pageData.featureText1,
                            renderOptionsLowMarginHeaders
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {pageData.featureImage2 && pageData.featureText2 && (
                    <div tw="w-full md:w-1/2 px-4">
                      <div tw="rounded-card bg-white">
                        <BackgroundImage
                          fluid={pageData.featureImage2.fluid}
                          style={{ height: '200px', width: '100%' }}
                          tw="rounded-t-2xl overflow-hidden"
                        />
                        <div tw="p-8 pt-4">
                          {renderRichText(
                            //@ts-ignore
                            pageData.featureText2,
                            renderOptionsLowMarginHeaders
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Container>
              {pageData.showCollectEmailForm && (
                <div tw="bg-primary-300 pt-10">
                  <Container tw="flex justify-center">
                    <CollectEmailFromPromotion abVariant={pageData.slug} />
                  </Container>
                </div>
              )}
            </div>
          )}
        {pageData.outroText && (
          <Container tw="mb-16">
            {/*//@ts-ignore*/}
            {renderRichText(pageData.outroText, renderOptions)}
          </Container>
        )}
        {pageData.bodyCta2Url && pageData.bodyCta2Text && (
          <div tw="flex justify-center">
            <a href={pageData.bodyCta2Url}>
              <Button
                variant="filled"
                color="primary"
                onClick={() => {
                  datalayer.triggerContentfulDatalayerAction(
                    pageData.bodyCta2DatalayerAction,
                    { ab_variant_id: pageData.slug }
                  );
                  if (pageData.bodyCta2Event) {
                    analytics.submitEvent(pageData.bodyCta2Event as any, {
                      location: pageData.bodyCta2EventLocation as any,
                    });
                  }
                }}
              >
                {pageData.bodyCta2Text}
              </Button>
            </a>
          </div>
        )}
        {pageData.faqItems?.length && (
          <Container tw="pt-16">
            <Heading variant="h2">Domande frequenti</Heading>
            <FaqAccordion
              faqItems={pageData.faqItems.map(({ answer, question }) => ({
                answer: renderRichText(
                  // @ts-ignore
                  answer,
                  renderOptions
                ) as ReactNode,
                question: question.question,
              }))}
            />
          </Container>
        )}
      </div>
      {pageData.showCollectEmailForm && (
        <div tw="bg-primary-300 py-10">
          <Container tw="flex justify-center">
            <CollectEmailFromPromotion abVariant={pageData.slug} />
          </Container>
        </div>
      )}
    </>
  );
};
export default PromotionPage;
