import React from 'react';
import CollectEmail, {
  SuccessComponent,
  TermsComponent,
} from '../CollectEmail/CollectEmail';
import Heading from '../../core/typography/Heading';
import 'twin.macro';
import { Link } from 'gatsby';

const CollectEmailFromPromotion = ({ abVariant }: { abVariant: string }) => {
  return (
    <div tw="w-full lg:w-3/5 p-4">
      <Heading variant="h4" tw="text-center">
        Vuoi ricevere informazioni?
      </Heading>
      <p tw="text-center text-paragraph mb-5">
        Lascia la tua mail per essere contattato dal nostro servizio clienti.
        Riceverai la brochure con i prezzi e uno sconto sul 1° servizio.
      </p>
      <div>
        <CollectEmail
          abVariant={abVariant}
          buttonLabel="Invia"
          type="promotion"
          autoConsent
        >
          <TermsComponent>
            <p tw="text-center">
              Cliccando su 'Invia' acconsenti al trattamento dei tuoi dati
              personali secondo la nostra privacy policy{' '}
              <Link to="/privacy-e-cookie-policy" tw="underline">
                disponibile qui.
              </Link>
            </p>
          </TermsComponent>
          <SuccessComponent>
            <Heading variant="h5" tw="mb-0 text-center">
              Siamo lieti di darti il benvenuto!
            </Heading>
            <p tw="text-paragraph text-center">
              Controlla la tua casella email. Se non vedi il nostro messaggio,
              controlla in spam.
            </p>
          </SuccessComponent>
        </CollectEmail>
      </div>
    </div>
  );
};
export default CollectEmailFromPromotion;
